import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cdkOutputs from "../cdk-outputs.json";

const API_URL = (cdkOutputs as any)[`${process.env.REACT_APP_STAGE}-covid-bs-Backend-Stack`][
  `${process.env.REACT_APP_STAGE}PublicApiGatewayEndpoint`
];

const createDayObject = (appointments: string[]) => {
  return appointments.reduce((days: any, date: string): any => {
    const day = date.substr(0, 10);
    days[day] = [...(days[day] || []), date].sort();
    return days;
  }, {});
};

interface LocationsState {
  status: "idle" | "loading" | "failed";
  freeAppointments: { [k: string]: string[] };
  appointments: { [k: string]: string[] };
}

const initialState: LocationsState = {
  status: "idle",
  freeAppointments: {},
  appointments: {},
};

export const getFreeAppointments = createAsyncThunk(
  "appointments/getFreeAppointments",
  async ({ location, date }: { location: string; date: string | Date }) => {
    const dateOfInterest = new Date(date).toISOString().substr(0, 10);
    const { freeSlots } = await (await fetch(`${API_URL}/free-timeslots/${location}/${dateOfInterest}`)).json();
    return createDayObject(freeSlots);
  }
);

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFreeAppointments.pending, (state) => {
      state.status = "loading";
      state.freeAppointments = {};
    });
    builder.addCase(getFreeAppointments.rejected, (state) => {
      state.status = "failed";
    });
    builder.addCase(getFreeAppointments.fulfilled, (state, action) => {
      state.status = "idle";
      state.freeAppointments = { ...action.payload };
    });
  },
});

export default appointmentsSlice.reducer;

import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { formatDate, formatTime } from "./common/date";

export function SuccessfulRegistration() {

  const {timeslot} = useParams();

  return (
    <div className="formWrapper wrapper">
      <h1 className="center huge">☑️</h1>
      <p className="center bold">{`${formatDate(timeslot as string)} ${formatTime(timeslot as string)}`}</p>
      <br />
      <p>Ihr Termin wurde erfolgreich gebucht. Sie erhalten eine E-Mail mit den Details zu Ihrer Buchhung.</p>
      <Link to="/">Weiteren Termin buchen</Link>
    </div>
  );
}

import { Link } from "react-router-dom";

export function Testablauf() {
  return (
    <div className="ueber_unsere_tests">
      <h1>Corona-Schnelltest im westlichen Ringgebiet in Braunschweig</h1>
      <div className="center spacer">
        <span className="chip purple">
          <Link to="/">Direkt zur Anmeldung</Link>
        </span>
      </div>
      <p>
        In unserer Teststelle in der Broitzemer Straße 233 bieten wir kostenlose Bürgertests auf Corona-Infektionen an.
        Dazu sind wir offiziell vom Gesundheitsamt der Stadt Braunschweig beauftragt. Unsere Mitarbeiter sind
        medizinisches Fachpersonal oder wurden durch dieses fachgerecht zur Testdurchfühung geschult.
      </p>
      <h3 className="center">Schnell, hygienisch und dank Voranmeldung ohne Wartezeit und Schlangestehen!</h3>
      <h2>Unsere Tests</h2>
      <p>
        Wir verwenden ausschließlich SARS-CoV-2-Antigen-Tests, die die Anforderungen des Paul-Ehrlich-Instituts (PEI)
        und des Robert-Koch Instituts (RKI) erfüllen. Unsere Tests befinden sich auf der offiziellen Liste des
        Bundesinstituts für Arzneimittel und Medizinprodukte (BfArM). Welches Produkt bei Ihrem Test verwendet wurde,
        teilen wir Ihnen mit dem Ergebnis per E-Mail mit.
      </p>
      <h2>Der Ablauf</h2>
      <ul>
        <li>
          Sie wählen auf uns unserer <Link to="/">Homepage</Link> einen Zeitpunkt für einen Test aus und melden sich
          unter Angabe Ihrer Daten an
        </li>
        <li>Wir senden Ihnen umgehend eine E-Mail mit einer Terminbestätigung</li>
        <li>
          Zum vereinbareten Termin prüfen wir Ihre Daten (bitte Personalausweis mitbringen) und nehmen schnell und
          unkompliziert einen Abstrich
        </li>
        <li>
          Etwa 15 Minuten nach Testdurchführung erhalten Sie von uns eine E-Mail mit dem Testergebnis. Die E-Mail
          enthält:
          <ul>
            <li>Ihr Ergebnis in leicht verständlicher Schriftform</li>
            <li>Eine Bescheinigung nach offiziellem Vordruck des Landes Niedersachsen über Ihr Testergebnis</li>
            <li>
              Optional und auf Ihren Wunsch: Einen QR-Code sowie einen Link zum Import des Ergebnisses in die
              Corona-Warn-App
            </li>
          </ul>
        </li>
        <li>
          Wir löschen Ihre Daten und geben diese nicht an Dritte weiter. Details:{" "}
          <Link to="/datenschutz">Datenschutz</Link>
        </li>
      </ul>
      <h2>Wie Sie uns finden</h2>
      Unsere Teststelle befindet sich in direkter Nähe zum Braunschweiger Stadtring im westlichen Ringgebiet gegenüber
      der Hochschule für Bildende Künste.
      <p>
        Friseursalon Cut and More
        <br /> Broitzemer Straße 233
        <br /> 38118 Braunschweig
      </p>
      <h2>Corona-Warn-App</h2>
      Auf ihren Wunsch stellen wir Ihnen ein Zertifikat für die Corona-Warn-App zur Verfügung, den Sie mit Ihrem
      Smartphone nutzen können. Dazu können Sie vor Ort in unserer Teststelle einen QR-Code scannen und zwischen einer
      anonymen und einer namentlichen Übertragung Ihres Testergebnisses an die Corona-Warn-App wählen.
      <h2>Verhalten bei positivem Testergebnis</h2>
      Die meisten Testergebnisse sind negativ. Sollte jedoch ein posities Ergebnis vorliegen, sind wir verpflichtet,
      dieses dem Gesundheitsamt zu melden. Natürlich teilen wir Ihnen Ihr Ergebnis so schnell wie möglich mit. Um ein
      falsch-positives Ergebnis auszuschließen, müssen Sie nun einen PCR-Test durchführen lassen. Dieser ist in dem Fall
      kostenlos und kann durch das Gesundheitsamt oder bei Ihrem Hausarzt durchgeführt werden.
      <h2>Wenn Sie fragen haben</h2>
      <p>Sollten Sie fragen haben, können Sie sich per E-Mail: covidtestbraunschweig@gmail.com an uns wenden.</p>
    </div>
  );
}

export function Datenschutz() {
  return (
    <div className="datenschutz">
      <h1>Datenschutz</h1>
      <p>
        Wir freuen uns, dass Sie unsere Website besuchen. Wir möchten Sie darüber informieren, dass wir Ihre Daten nicht
        an Dritte weitergeben. Es sei denn zu Abrechungszwecken oder zur Medlung eines positiven Corona-Test-Ergebnisses
        an das zuständige Gesundheitsamt. Für Details lesen Sie einfach weiter.
      </p>
      <h2>Grundlegendes</h2>
      Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und
      Verwendung personenbezogener Daten durch den Websitebetreiber (siehe „Wer wir sind“) informieren. Der
      Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und
      entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser
      Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die
      Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen. Definitionen der verwendeten Begriffe (z.B.
      “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
      <h2>Wer wir sind</h2>
      Verantwortliche im Sinne der Datenschutz-Grundverordnung, sowie sonstiger in der Europäischen Union geltenden
      Datenschutzgesetze ist:
      <p>
        Katarina Kauz Schulz
        <br /> Broitzemer Straße 233
        <br /> 38118 Braunschweig
      </p>
      <p>
        <br /> Telefon: 0178 1121757
      </p>
      <p>E-Mail: covidtestbraunschweig@gmail.com</p>
      <h2>Welche personenbezogenen Daten wir sammeln und warum wir sie sammeln</h2>
      <h3>Test-Registierungsdaten</h3>
      Melden Sie sich zu einem Test an, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung zurückgegriffen
      werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben, es sei denn es liegt ein
      positives Testergebnis vor: In diesem Fall sind wir zur Weitergabe der Daten an das Gesundheitsamt verpflichtet.
      <br />
      <br />
      Zuständiges Gesundheitsamt: <br />
      <br />
      Stadt Braunschweig
      <br />
      Fachbereich Soziales und Gesundheit
      <br />
      Hamburger Str. 226
      <br />
      38114 Braunschweig
      <br />
      Niedersachsen
      <br />
      <br />
      Telefon: 0531 470-7022
      <br />
      Fax: 0531 470-7040
      <br />
      <br />
      E-Mail: Gesundheitsamt@braunschweig.de
      <br />
      COVID-19 Kontaktinformationen: gesundheitsschutz@braunschweig.de
      <h3>Zugriffsdaten</h3>
      Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1
      lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf dem Server der
      Website ab. Folgende Daten werden so protokolliert:
      <ul>
        <li>Besuchte Website</li>
        <li>Verwendeter Browser</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Verwendete IP-Adresse</li>
        <li>Uhrzeit der Serveranfrage</li>
      </ul>
      Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die Speicherung der Daten
      erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus Beweisgründen
      aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
      <h3>Kontaktdaten</h3>
      Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden Ihre
      Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen werden kann.
      Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.
      <h3>Rechte des Nutzers</h3>
      Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen
      Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die
      Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr
      Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden,
      können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.
      <h3>Löschung von Daten</h3>
      Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung)
      kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für
      ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht.
      Falls eine Löschung nicht durchgeführt werden kann, da die Daten für Abrechungszwecke oder zulässige gesetzliche
      Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten
      gesperrt und nicht für andere Zwecke verarbeitet.
      <h3>Widerspruchsrecht</h3>
      Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer
      personenbezogenen Daten zu jeder Zeit widersprechen. Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft
      über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung,
      Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten,
      wenden Sie sich bitte an folgende E-Mail-Adresse: covidtestbraunschweig@gmail.com
      <h2>Datenschutzhinweise Dritter</h2>
      <h3>Corona-Warn-App (Nutzung frewillig und nur auf Ihren Wunsch)</h3>
      <i>
        Wenn Sie jünger als 16 Jahre alt sind, besprechen Sie die Nutzung der App bitte mit Ihren Eltern oder Ihrer
        sorgeberechtigten Person.
      </i>
      <br />
      <br />
      Sie möchten die Corona-Warn-App („App“) des Robert Koch-Instituts („RKI“) zum Abruf Ihres Testergebnisses eines
      Antigentests verwenden. Um Ihr Testergebnis über die App abrufen zu können ist es notwendig, dass Ihr Testergebnis
      von der Teststelle an das Serversystem des RKI übermittelt wird. Verkürzt dargestellt erfolgt dies, indem die
      Teststelle Ihr Testergebnis, verknüpft mit einem maschinenlesbaren Code, auf einem hierfür bestimmten Server des
      RKI ablegt. Der Code ist Ihr Pseudonym, weitere Angaben zu Ihrer Person sind für die Anzeige des Testergebnisses
      in der App nicht erforderlich. Sie können die Anzeige des Testergebnisses jedoch für sich durch Angabe Ihres
      Namens, Vornamens und Geburtsdatums personalisieren lassen.
      <br />
      <br />
      Der Code wird aus dem vorgesehenen Zeitpunkt des Tests und einer Zufallszahl gebildet. Die Bildung des Codes
      erfolgt, indem die vorgenannten Daten so miteinander verrechnet werden, dass ein Zurückrechnen der Daten aus dem
      Code nicht mehr möglich ist.
      <br />
      <br />
      Sie erhalten eine Kopie des Codes in der Darstellung eines QR-Codes, der durch die Kamerafunktion Ihres
      Smartphones in die App eingelesen werden kann. Alternativ können Sie den pseudonymen Code auch als Internetverweis
      erhalten („App Link“), der von der App geöffnet und verarbeitet werden kann. Nur hierdurch ist eine Verknüpfung
      des Testergebnisses mit Ihrer App möglich. Mit Ihrer Einwilligung können Sie dann Ihr Testergebnis mit Hilfe der
      App abrufen. Ihr Testergebnis wird automatisch nach 21 Tagen auf dem Server gelöscht. Wenn Sie mit der
      Übermittlung Ihres pseudonymen Testergebnisses mittels des Codes an die App-Infrastruktur zum Zweck des Testabrufs
      einverstanden sind, bestätigen Sie dies bitte gegenüber den Mitarbeitern der Teststelle. Sie können Ihre
      Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Bitte beachten Sie jedoch, dass aufgrund
      dervorhandenen Pseudonymisierung eine Zuordnung zu Ihrer Person nicht erfolgen kann und daher eine Löschung Ihrer
      Daten erst mit Ablauf der 21-tägigen Speicherfrist automatisiert erfolgt. Einzelheiten hierzu finden Sie zudem in
      den »Datenschutzhinweisen« der Corona-Warn-App des RKI.
    </div>
  );
}

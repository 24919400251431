import React from "react";
import { SelectAppointmentDayAndTime } from "./SelectAppointmentDayAndTime";
import { useForm } from "react-hook-form";
import { emailRegex } from "./common/validation";
import { open } from "./features/alertSlice";
import { useAppDispatch } from "./app/hooks";
import cdkOutputs from "./cdk-outputs.json";
import { countries } from "./common/countries";
import { Link, useNavigate } from "react-router-dom";
import cwaLogo from "./images/logo_cwa.png";

const API_URL = (cdkOutputs as any)[`${process.env.REACT_APP_STAGE}-covid-bs-Backend-Stack`][
  `${process.env.REACT_APP_STAGE}PublicApiGatewayEndpoint`
];

export function Form() {
  const [disabled, setDisabled] = React.useState(false);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem("formData") || "{}"),
  });
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    setDisabled(true);
    if (data.saveData) {
      localStorage.setItem("formData", JSON.stringify({ ...data, timeslot: undefined }));
    } else {
      localStorage.removeItem("formData");
    }

    const location = "bs-bro";
    try {
      const response = await fetch(`${API_URL}/reserve/${location}/${data.timeslot}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        navigate(`/success/${data.timeslot}`);
      }
      if (response.status === 409) {
        const message = "Fehler: Termin zwischenzeitlich gebucht. Bitte wählen Sie einen anderen Zeitpunkt.";
        dispatch(open({ message, type: "error" }));
      }
    } catch (err) {
      dispatch(open({ message: JSON.stringify(err), type: "error" }));
    } finally {
      setDisabled(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>Anmeldung zum kostenfreien Corona-Test</h1>
      <br />
      <label htmlFor="location">
        Infos zum Ablauf:
        <br />
        <Link to="/testablauf" className="inputLikeText" target="_blank">
          Testablauf &amp; Infos
        </Link>
      </label>{" "}
      <label htmlFor="location">
        Ort:
        <br />
        <a
          className="inputLikeText"
          href="https://www.google.com/maps/place/Broitzemer+Str.+233,+38118+Braunschweig/"
          target="_blank"
        >
          Corona Testcenter bei Cut and More, Broitzemer Str. 233, 38118 Braunschweig
        </a>
      </label>
      <SelectAppointmentDayAndTime
        formHookConfig={{ ...register("timeslot", { required: true, minLength: 24, maxLength: 24 }) }}
        errors={errors.timeslot}
      />
      <label htmlFor="firstName">
        Vorname:
        <br />
        <input
          type="text"
          className={errors.firstName && "validationErrorInput"}
          id="firstName"
          {...register("firstName", { required: true, minLength: 2, maxLength: 50 })}
        />
        {errors.firstName && <span className={"validationError"}>Bitte geben Sie einen Vornamen ein</span>}
      </label>
      <label htmlFor="lastName">
        Nachname:
        <br />
        <input
          type="text"
          className={errors.lastName && "validationErrorInput"}
          id="lastName"
          {...register("lastName", { required: true, minLength: 2, maxLength: 50 })}
        />
        {errors.lastName && <span className={"validationError"}>Bitte geben Sie einen Nachnamen ein</span>}
      </label>
      <label htmlFor="street">
        Straße &amp; Hausnummer:
        <br />
        <input
          type="text"
          className={errors.address?.street && "validationErrorInput"}
          id="street"
          {...register("address.street", { required: true, minLength: 2, maxLength: 50 })}
        />
        {errors.address?.street && <span className={"validationError"}>Bitte geben Sie Straße und Hausnummer ein</span>}
      </label>
      <label htmlFor="postalCode">
        Postleitzahl:
        <br />
        <input
          type="text"
          className={errors.address?.postalCode && "validationErrorInput"}
          id="postalCode"
          {...register("address.postalCode", { required: true, minLength: 5, maxLength: 5 })}
        />
        {errors.address?.postalCode && <span className={"validationError"}>Bitte geben Sie eine Postleitzahl ein</span>}
      </label>
      <label htmlFor="city">
        Ort:
        <br />
        <input
          type="text"
          className={errors.address?.city && "validationErrorInput"}
          id="city"
          {...register("address.city", { required: true, minLength: 2, maxLength: 50 })}
        />
        {errors.address?.city && <span className={"validationError"}>Bitte geben Sie einen Ort ein</span>}
      </label>
      <label htmlFor="nationality">
        Nationalität:
        <br />
        <select
          id="nationality"
          className={errors.nationality && "validationErrorInput"}
          {...register("nationality", { required: true, minLength: 2, maxLength: 50 })}
        >
          {Object.entries(countries).map((country) => {
            const [short, long] = country;
            return (
              <option key={short} value={short}>
                {long}
              </option>
            );
          })}
        </select>
        {errors.nationality && <span className={"validationError"}>Bitte geben Sie Ihre Nationalität ein</span>}
      </label>
      <label htmlFor="dateOfBirth">
        Geburtsdatum:
        <br />
        <input
          type="date"
          className={errors.dateOfBirth && "validationErrorInput"}
          id="dateOfBirth"
          {...register("dateOfBirth", { required: true, min: "1920-01-01", max: new Date().toISOString().split("T")[0] })}
        />
        {errors.dateOfBirth && <span className={"validationError"}>Bitte geben Sie Ihr Geburtsdatum ein</span>}
      </label>
      <label htmlFor="email">
        E-Mail-Adresse:
        <br />
        <input
          type="email"
          className={errors.email && "validationErrorInput"}
          id="email"
          {...register("email", { required: true, pattern: emailRegex })}
        />
        {errors.email && <span className={"validationError"}>Bitte geben Sie eine E-Mail-Adresse ein</span>}
      </label>
      <label htmlFor="tel">
        Mobilfunknummer:
        <br />
        <input
          type="tel"
          id="tel"
          className={errors.phone && "validationErrorInput"}
          {...register("phone", { required: true })}
        />
        {errors.phone && <span className={"validationError"}>Bitte geben Sie eine Mobilfunknummer ein</span>}
      </label>
      <label htmlFor="dataProtectionAgreement">
        <input
          type="checkbox"
          className={errors.dataProtectionAgreement && "validationErrorInput"}
          id="dataProtectionAgreement"
          {...register("dataProtectionAgreement", { required: true })}
        />
        Ich akzeptiere die{" "}
        <a href="/datenschutz" target="_blank">
          Datenschutzbedingungen
        </a>
        . Mir ist bewusst, dass positive Testergebnisse dem Gesundheitsamt gemeldet werden.
        {errors.dataProtectionAgreement && (
          <span className={"validationError"}>Bitte stimmen Sie den Datenschutzbestimmungen zu</span>
        )}
      </label>
      <label htmlFor="healthy">
        <input
          type="checkbox"
          className={errors.healthy && "validationErrorInput"}
          id="healthy"
          {...register("healthy", { required: true })}
        />
        Ich bestätige, dass ich nur wenn ich keine Symptome aufweise, zum Test erscheine.
        {errors.healthy && <span className={"validationError"}>Bitte stimmen Sie zu.</span>}
      </label>
      <label htmlFor="saveData">
        <input type="checkbox" id="saveData" {...register("saveData", { required: false })} />
        Für zukünftige schnelle erneute Buchung, Daten lokal auf meinem Gerät speichern.
      </label>
      <h3>Anzeige Ihres Testergbnisses in der Corona-Warn-App</h3>
      <img src={cwaLogo} alt="Logo Corona-Warn-App" />
      {errors.cwa && <span className={"validationError"}>Bitte treffen Sie ein Auswahl</span>}
      <label htmlFor="cwaNot">
        <input type="radio" id="cwaNot" value="cwaNot" {...register("cwa", { required: true })} />
        <p className="indented">
          <span className="bold"> Keine Daten an die Corona Warn App übermitteln.</span>
          <br />
          Wenn Sie dies Option wählen erhalten Sie Ihr Testergebis nicht in der Corona Warn App, sondern nur per E-Mail.
        </p>
      </label>
      <label htmlFor="cwaAnonymous">
        <input type="radio" id="cwaAnonymous" value="cwaAnonymous" {...register("cwa", { required: true })} />
        <p className="indented">
          <span className="bold"> Einwilligung zur pseudonymisierten Übermittlung (nicht-namentliche Anzeige)</span>
          <br />
          Hiermit erkläre ich mein Einverständnis zum Übermitteln meines Testergebnisses und meines pseudonymen Codes an
          das Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Das Testergebnis
          in der App kann hierbei nicht als namentlicher Testnachweis verwendet werden. Mir wurden Hinweise zum
          Datenschutz ausgehändigt.
        </p>
      </label>
      <label htmlFor="cwaNamed">
        <input type="radio" id="cwaNamed" value="cwaNamed" {...register("cwa", { required: true })} />
        <p className="indented">
          <span className="bold"> Einwilligung zur personalisierten Übermittlung (namentlicher Testnachweis)</span>
          <br />
          Hiermit erkläre ich mein Einverständnis zum Übermitteln des Testergebnisses und meines pseudonymen Codes an
          das Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Ich willige
          außerdem in die Übermittlung meines Namens und Geburtsdatums an die App ein, damit mein Testergebnis in der
          App als namentlicher Testnachweis angezeigt werden kann. Mir wurden Hinweise zum Datenschutz ausgehändigt.
        </p>
      </label>
      <br />
      <label htmlFor="submit">
        {errors.timeslot && (
          <>
            <span className={"validationError submitValidationError"}>
              Bitte wählen Sie einen Zeitpunkt für Ihren Termin aus der Auswahlliste oben
            </span>
            <br />
          </>
        )}
        <input type="submit" value={disabled ? "Reservierung senden..." : "Termin reservieren"} disabled={disabled} />
      </label>
    </form>
  );
}

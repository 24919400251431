import { createSlice } from "@reduxjs/toolkit";

interface AlertState {
  status: "idle" | "loading" | "failed"; // TODO: do I need that and why?
  open: boolean;
  type: "success" | "info" | "warning" | "error" | undefined;
  autoHideDuration: number;
  message: string;
}

const initialState: AlertState = {
  status: "idle",
  open: false,
  type: "info",
  autoHideDuration: 6000,
  message: "Everything is fine",
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    open: (state, action) => {
      state.open = true;
      state.type = action.payload.type;
      state.autoHideDuration = action.payload.autoHideDuration || 6000;
      state.message = action.payload.message;
    },
    close: (state) => {
      state.open = false;
    },
  },
});

export const { open, close } = alertSlice.actions;

export default alertSlice.reducer;
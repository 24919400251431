export const formatDate = (date: string | Date): string => {
  const d = new Date(date);

  return `${d.toLocaleDateString("de-DE", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })}`;
};

export const formatTime = (date: string | Date): string => {
  const d = new Date(date);

  return `${d.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  })} Uhr`;
};
export const isDateInFuture = (date: string): boolean => {
  const now = new Date();
  const dateToCheck = new Date(date);
  return dateToCheck > now;
};

export const getWeekNumber = (date: Date): number => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  return Math.ceil(((d.getTime() - new Date(d.getFullYear(), 0, 1).getTime()) / 86400000 + 1) / 7);
};

import { spawn } from "child_process";
import { ReactChild, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import { formatDate, formatTime } from "./common/date";
import { getFreeAppointments } from "./features/appointmentsSlice";

export function SelectAppointmentDayAndTime({ formHookConfig, errors }: { formHookConfig: any; errors: any }) {
  const [days, setDays] = useState(
    new Array(14).fill("").map((_, i) => new Date(new Date().setDate(new Date().getDate() + i * 1)))
  );
  const [day, setDay] = useState(new Date().toISOString());
  const dispatch = useAppDispatch();
  const { freeAppointments: appointments, status } = useAppSelector((state: RootState) => state.appointments);

  let lookWeeksForward = 0;
  const lookWeeksForwardMax = 7;

  // useEffect(() => {
  //   const getNextFreeTimeSlots = async () => {
  //     let freeTimeSlots = {};

  //     while (!Object.keys(freeTimeSlots).length && lookWeeksForward < lookWeeksForwardMax) {
  //       const date = new Date(new Date().setDate(new Date().getDate() + lookWeeksForward * 1));
  //       freeTimeSlots = await dispatch(getFreeAppointments({ location: "bs-bro", date })).unwrap();
  //       lookWeeksForward++;
  //     }
  //   };

  //   getNextFreeTimeSlots();
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getFreeAppointments({ location: "bs-bro", date: day }));
  }, [day]);

  return (
    <>
      <label>
        Tag:
        <br />
        <select id="day" name="day" onChange={(e) => setDay(e.target.value)} value={day} required>
          {days.map((date) => {
            return (
              <option value={date.toISOString()} key={date.toISOString()} className="day">{`${formatDate(
                date
              )}`}</option>
            );
          })}
        </select>
      </label>
      <label>
        Uhrzeit:
        <br />
        {status === "loading" && <h3>Lädt...</h3>}
        {status === "idle" && !Object.values(appointments).length && <h3>Leider keine Zeiten verfügbar am {`${formatDate(day)}`}. Bitte wählen Sie einen anderen Tag aus der obigen Liste.</h3>}
        {!!Object.values(appointments).length && (
          <select id="appointments" {...formHookConfig} required>
            <option disabled selected={true}>Bitte wählen Sie einen Zeitpunkt</option>
            {Object.entries(appointments || {}).map((tuple) => {
              const [day, times] = tuple;
              return (
                <Optgroup date={day} key={day}>
                  {times.map((time: string) => (
                    <Option date={time} key={`${day}-${time}`} />
                  ))}
                </Optgroup>
              );
            })}
          </select>
        )}
        {errors && <span className={"validationError"}>Bitte wählen Sie einen Zeitpunkt</span>}
      </label>
    </>
  );
}

export function Option({ date }: { date: string }) {
  return (
    <>
      <option value={date} className="timeslot">{`${formatTime(date)}`}</option>
    </>
  );
}

export function Optgroup({ date, children }: { date: string; children: ReactChild[] }) {
  return (
    <>
      <optgroup label={formatDate(date)}>{children}</optgroup>
    </>
  );
}

export function NoFreeAppointments() {
  return (
    <div className=" band">
      <span>
        Aktuell keine freien Termine vorhanden. <br /> <br />
        Sollten Termine abgesagt werden, werden diese hier erneut freigeschaltet.
      </span>
    </div>
  );
}

export function LoadMore({ action }: { action: any }) {
  return <span onClick={action}>Spätere Termine laden</span>;
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function Header() {
  const [isPreProd, setIsPreProd] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if ((window as any).location.host !== `covid-bs.de`) {
      setIsPreProd(true);
    }
  }, []);

  return (
    <>
      {isPreProd && (
        <div className="band">
          Pre-Production: Dies ist eine Testumgebung! <br />
          Bitte nur Testdaten! Gebuchte Termine finden nicht statt!
        </div>
      )}
      <div className="header wrapper">
        <h1 className="domain" onClick={() => navigate("/")}>
          covid-bs.de
        </h1>
        <h3 className="chip">Kostenloser Bürgertest</h3>
        <h2>Corona-Testzentrum Broitzemer Straße 233, Braunschweig</h2>
        {/* <p className="donate">
        Tests sind für Sie kostenlos. Diese werden durch die Kassenärztlichen Vereinigungen bezahlt. Wir spenden ab
        jetzt 100% unseres Gewinns aus Tests an lokale gemeinnützige Zwecke.
      </p> */}
      </div>
    </>
  );
}

import { useParams } from "react-router";
import { formatDate, formatTime } from "./common/date";
import cdkOutputs from "./cdk-outputs.json";
import React from "react";
import { Link } from "react-router-dom";

const API_URL = (cdkOutputs as any)[`${process.env.REACT_APP_STAGE}-covid-bs-Backend-Stack`][
  `${process.env.REACT_APP_STAGE}PublicApiGatewayEndpoint`
];

export function Cancel() {
  const [successfullyCanceled, setSuccessfullyCanceled] = React.useState(false);
  const params = useParams();

  // TODO: first of all, check if appointment is still valid!

  const handleCancellation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/cancel/${params.location}/${params.date}/${params.id}`);

      if (response.status === 200) {
        setSuccessfullyCanceled(true);
      } else {
        alert(
          `Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Ihr Termin konnte nicht storniert werden!\r\n${JSON.stringify(
            response
          )}`
        );
      }
    } catch (err) {
      alert(`Ihr Termin konnte nicht storniert werden!\n${JSON.stringify(err)}`);
    }
  };

  if (successfullyCanceled) {
    return (
      <>
        <h1 className="center huge">☑️</h1>

        <p className="center bold">
          Ihr Termin: {`${formatDate(params.date as string)} ${formatTime(params.date as string)}`} wurde erfolgreich
          storniert. Sie erhalten eine E-Mail zur Bestätigung.
        </p>
        <p>
          <Link to="/">Weiteren Termin buchen</Link>
        </p>
      </>
    );
  }

  return (
    <>
      <h3>
        Sie möchten Ihren Termin am {`${formatDate(params.date as string)} ${formatTime(params.date as string)}`}{" "}
        stornieren?
      </h3>
      <p>Sind Sie sicher, dass Sie Ihren Termin absagen wollen?</p>
      <form onSubmit={handleCancellation}>
        <input type="submit" value="Ja, Termin stornieren" />
      </form>
      <br />
      <p className="bold">Falls Sie Ihren Termin behalten möchten, schließen Sie einfach diese Seite.</p>
    </>
  );
}

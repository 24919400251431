import React, { Suspense } from "react";
import { Form } from "./Form";
import { Header } from "./Header";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Cancel } from "./Cancel";
import { Navigation } from "./Navigation";
import { Impressum } from "./Impressum";
import { Datenschutz } from "./Datenschutz";
import { SuccessfulRegistration } from "./SuccessfulRegistration";
import { Testablauf } from "./Testablauf";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Form />} />
                <Route path="/cancel/:location/:date/:id" element={<Cancel />} />
                <Route path="/testablauf" element={<Testablauf />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="/success/:timeslot" element={<SuccessfulRegistration />} />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </>
  );
}

function Layout() {
  return (
    <>
      <Header />
      <div id="main" className="formWrapper wrapper">
        <Outlet />
      </div>
      <Navigation />
    </>
  );
}

export default App;

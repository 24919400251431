export function Impressum() {
  return (
    <div className="impressum">
      <h1>Impressum</h1>
      <p>Angaben gemäß § 5 TMG:</p>
      <p>
        Katarina Kauz Schulz
        <br /> Broitzemer Straße 233
        <br /> 38118 Braunschweig
      </p>
      <p>
        <br /> Telefon: 0178 1121757
      </p>
      <p>E-Mail: covidtestbraunschweig@gmail.com</p>
      <p>
        Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer
        Links. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich.
      </p>
    </div>
  );
}
